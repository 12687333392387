import ReactPixel from 'react-facebook-pixel'
import { PixelId } from './config'
export function scrollIntoViewWithOffset(viewRef: any, offsetPx: number): any {
  window.scrollTo({
    behavior: 'smooth',
    top: viewRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offsetPx,
  })
}

export const scrollOffset = 120

export enum FbEvent {
  AppStoreOpen = 'AppStoreOpen',
  PlayStoreOpen = 'PlayStoreOpen',
  WebAppOpen = 'WebAppOpen',
}

export const trackEvent = (event: FbEvent) => {
  ReactPixel.trackSingle(PixelId, 'Purchase', { app: event }) // For tracking default events.
  // For tracking page view
  // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
}
