import friendsIcon from '../assets/friends_icon.png'
import iconQuality from '../assets/images/icon-quality.png'
import iconFast from '../assets/images/icon-fast.png'
import iconShoot from '../assets/images/icon-shoot.png'
import iconReview from '../assets/images/icon-review.png'
import iconFun from '../assets/images/icon-fun.png'
import FeatureCard from './FeatureCard'

const WhySolutionSection = () => {
  return (
    <div className="flex flex-col mt-28">
      <div className="text-white flex flex-col items-center justify-center ">
        <p className="text-5xl font-bold pb-4">Perché Special Quiz SSM ?</p>
        <p className="text-white/90 text-lg lg:text-center">
          L'app mobile e web, ideale per chi vuole prepararsi al test SSM 2024 ottimizzando il proprio tempo con uno
          strumento di qualità. <br />
        </p>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap items-baseline gap-8 z-10 mt-10">
        <FeatureCard
          imageSrc={iconShoot}
          title={'Ripassa dai tuoi errori'}
          caption={
            "Dopo ogni quiz sarà l'app ad indicarti dove sono le tue lacune, selezionando gli argomenti che dovresti rivedere e mostrandoti le domande che hai sbagliato, materia per materia."
          }
          cta={'→ Non devi fare altro. Sembra magia.'}
        />
        <FeatureCard
          imageSrc={iconFast}
          title={'Design e usabilità'}
          caption={
            "Il nostro Design minimalista crea un'esperienza di apprendimento semplice e divertente. Lo confermano migliaia di medici specializzandi che hanno scelto Special Quiz prima di te."
          }
          cta={'→ 10000+ utenti registrati'}
        />
        <FeatureCard
          imageSrc={friendsIcon}
          title={'Sfida i tuoi colleghi'}
          caption={
            'Divertiti a sfidare i tuoi amici con i quiz condivisi, vinca il migliore! Crea quiz su argomenti specifici che avete studiato insieme.'
          }
          cta={'→ Tutti per uno. Uno per tutti.'}
        />
      </div>

      <div className="text-white flex flex-col items-center justify-center mt-20">
        <p className="text-5xl font-bold pb-4">Le nostre domande</p>
        {/* <p className="text-white/90 text-lg lg:text-center drop-shadow-xl">
          Sono perfette per ripassare nei mesi prima dell'esame e studiare in prospettiva del futuro esame.
          <br />
        </p> */}
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap items-baseline gap-8 z-10 mt-10">
        <FeatureCard
          imageSrc={iconReview}
          title={'Spiegazioni e Bigliografia'}
          caption={
            "Studia gli argomenti che ti interessano, ogni domanda contiene una spiegazione approfondita della risposta corretta e in generale dell'argomento trattato, che puoi approfondire con le bigliografie allegate."
          }
        />
        <FeatureCard
          imageSrc={iconQuality}
          title={'Domande di qualità e aggiornate'}
          caption={
            'Le nostre domande sono preparate da Medici Specialisti del settore, aggiornate alle ultime Linee Guida. Sono strutturate a caso clinico, come nei più recenti quiz SSM ministeriali.'
          }
        />
        <FeatureCard
          imageSrc={iconFun}
          title={'Difficoltà variabili'}
          caption={
            "Monitora i tuoi progressi con i nostri quiz con le nostre domande a difficolta' pari o superiori a quelle dei test ministeriali. Rendi possibile l'impossibile, diventa un vero medico."
          }
        />
      </div>
    </div>
  )
}

export default WhySolutionSection
