import Logo from '../assets/logo.png'
import instagramLogo from '../assets/images/instagram-logo.png'
import facebookLogo from '../assets/images/facebook-logo.png'
const Footer = () => {
  const emailAddress = 'info@specialquiz.it'

  return (
    <div className="py-8">
      <div className="flex flex-row flex-wrap pt-8 border-t-2 border-white/20 px-6 justify-around">
        <div className="pb-8 lg:pb-0 lg:order-1">
          <div className="flex flex-row  items-center gap-4 pb-4 text-white">
            <img src={Logo} className={'w-20 h-full'} />
            Special Quiz
          </div>
          <p className="text-white/80">Copyright © Special Quiz 2024</p>
          <p className="text-white/80">Special Quiz s.r.l.s. P.IVA 09546521213</p>
        </div>

        <div className="lg:order-0 text-white flex flex-col">
          <p className="text-xl">Contatti</p>
          <a
            className="text-white/80 underline"
            href={`mailto:${emailAddress}?subject=Richiesta%20informazioni%20business`}
          >
            {emailAddress}
          </a>
          <a href="./cookie-policy" className="text-white/80 underline">
            Cookie Policy
          </a>
          <a href="./privacy-policy" className="text-white/80 underline">
            Privacy Policy
          </a>
          <a href="/ssm/terms-and-conditions" className="text-white/80 underline">
            Termini e Condizioni
          </a>
        </div>
        <div className="text-white">
          <div className="flex flex-row gap-4 mt-4">
            <a
              href="https://www.instagram.com/specialquizssm/"
              className="flex flex-row  items-center gap-4 pb-4 text-white"
            >
              <img src={instagramLogo} className={'w-10  h-10'} />
            </a>
            <a
              href="https://www.facebook.com/specialquizssm/"
              className="flex flex-row  items-center gap-4 pb-4 text-white"
            >
              <img src={facebookLogo} className={'w-10 h-10'} />
            </a>
          </div>
          #specialquizssm
        </div>
      </div>
    </div>
  )
}
export default Footer
