import { OsTypes, osName } from 'react-device-detect'
import doctors from '../assets/doctors.png'
import PlanCard from './PlanCard'
import { FbEvent, trackEvent } from '../helpers'
export type PlansSectionProps = {
  contactFormRef: any
}

const AppLink = {
  [OsTypes.IOS]: { url: 'https://apps.apple.com/it/app/id1524977832', eventType: FbEvent.AppStoreOpen },
  [OsTypes.Android]: {
    url: 'https://play.google.com/store/apps/details?id=com.specialquizapp',
    eventType: FbEvent.PlayStoreOpen,
  },
  [OsTypes.Windows]: { url: 'https://ssm.specialquiz.it', eventType: FbEvent.WebAppOpen },
  [OsTypes.WindowsPhone]: { url: 'https://ssm.specialquiz.it', eventType: FbEvent.WebAppOpen },
  [OsTypes.MAC_OS]: { url: 'https://ssm.specialquiz.it', eventType: FbEvent.WebAppOpen },
}

const PlansSection = ({ contactFormRef }: PlansSectionProps) => {
  return (
    <div className="flex flex-col relative">
      <div className="flex flex-row flex-1">
        <div className="flex flex-col text-white justify-center ">
          <p className="text-5xl font-bold">80% del risultato con il 20% del lavoro</p>
          <p className="text-white/60 mb-10 mt-6 text-lg">{`Per indovinare almeno l'80% delle domande del test SSM o MMG focalizza la tua preparazione sui quiz. Preparati con simulazioni della difficoltà giusta. Utilizza l'app che ti permette di tagliare sui tempi morti della revisione e studia sui libri solo ciò che è veramente necessario.`}</p>
          <p className="text-primary text-lg font-bold">→ Adesso, fai la tua scelta</p>
        </div>
        <img className="invisible w-0 lg:w-full lg:visible" src={doctors} style={{ maxWidth: 800, marginLeft: 20 }} />
      </div>
      <div className="flex flex-row flex-wrap gap-8 mt-20">
        <PlanCard
          title={'Piano Base Gratuito'}
          caption={'Scarica gratis la app e registrati per esercitarti sugli esami degli anni passati.'}
          buttonText={'Inizia Gratuitamente'}
          features={['App mobile e web', 'Quiz personalizzabili', 'Esami dei concorsi SSM e MMG']}
          onPress={() => {
            trackEvent(AppLink[osName].eventType)
            window.open(AppLink[osName].url, '_blank', 'noopener,noreferrer')
          }}
        />
        <PlanCard
          tag={'Consigliato'}
          title={'Premium'}
          caption={
            'Scegli il nostro piano Premium. Tutti i vantaggi del piano Base più tutte le nostre QBank per prepararti al meglio.'
          }
          price={'23.98 €/mese'}
          buttonText={'Trai il meglio'}
          features={[
            'Tutti i vantaggi del piano Base',
            'Accesso a tutte le QBank',
            'Tutte le spiegazioni degli esami passati',
            'Sfrutta al massimo la app',
          ]}
          highlight
          onPress={() => {
            trackEvent(AppLink[osName].eventType)
            window.open(AppLink[osName].url, '_blank', 'noopener,noreferrer')
          }}
        />
        <PlanCard
          title={'QBank per tutte le tasche'}
          caption={
            'Personalizza la tua app. Scegli solo i pacchetti di domande che ti servono. Le nostre QBank hanno un prezzo variabile a seconda della difficoltà e dei contenuti.'
          }
          price={'A partire da soli 2 €'}
          buttonText={'Un passo alla volta'}
          features={['Tutti i vantaggi del piano Base', 'Scegli le QBank che ti servono']}
          onPress={() => {
            trackEvent(AppLink[osName].eventType)
            window.open(AppLink[osName].url, '_blank', 'noopener,noreferrer')
          }}
        />
      </div>
    </div>
  )
}

export default PlansSection
