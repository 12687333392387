import { useEffect, useRef, useState } from 'react'
import { osName } from 'react-device-detect'
import ReactPixel from 'react-facebook-pixel'
import AppDemoSection from '../components/AppDemoSection'
import ContactFormSection from '../components/ContactFormSection'
import Footer from '../components/Footer'
import { Header } from '../components/Header'
import HeroSection from '../components/HeroSection'
import PlansSection from '../components/PlansSections'
import WhySolutionSection from '../components/WhySolutionSection'
import { PixelId } from '../config'
import CircleFull from '../svg/circle-full.svg'
import Vector1 from '../svg/vector-1.svg'
import Vector2 from '../svg/vector-2.svg'
import Vector3 from '../svg/vector-3.svg'
import Vector4 from '../svg/vector-4.svg'
import Vector5 from '../svg/vector-5.svg'

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
ReactPixel.init(PixelId, undefined, options)

export const scrollOffset = 120

export default function Home() {
  const Spacer = ({ classNames, style }: { classNames?: string; style?: any }) => {
    return <div className={`lg:pt-36 pt-20 ${classNames ?? ''}`} style={style}></div>
  }

  const whySolutionSectionRef = useRef(null)
  const plansSectionRef = useRef(null)
  const contactFormRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    ReactPixel.pageView()
    return () => {}
  }, [])

  return (
    <div className="not-selectable bg-green-dark">
      <title>Special Quiz</title>
      <Header
        whySolutionRef={whySolutionSectionRef}
        plansSectionRef={plansSectionRef}
        contactFormRef={contactFormRef}
        onLoaded={() => setIsLoaded(true)}
      />
      {!isLoaded && (
        <div className="h-[8000px] flex flex-1 items-center justify-center">
          <div className="loader absolute top-[200px]"></div>
        </div>
      )}
      {osName !== 'iOS' && osName !== 'Android' && (
        <div className="z-1">
          {/* FIRST SECTION BG */}
          <img src={Vector5} alt="Bg Vector" className="absolute w-[60vw] opacity-60 -top-[100px] right-0 " />
          <img
            src={CircleFull}
            alt="Bg Vector"
            className="absolute w-[60vw] opacity-60 -top-[150px] right-[5vw] ball"
          />
          {/* SECOND SECTION BG */}
          <img
            src={Vector4}
            alt="Bg Vector"
            className="absolute w-[60vw] opacity-60 top-[500px] -left-[200px] z-1 ball-h"
          />
          <img
            src={Vector2}
            alt="Bg Vector"
            className="absolute w-[60vw] opacity-60 top-[700px] -left-[200px] z-1 ball-h"
          />
          {/* THIRD SECTION BG */}
          <img
            src={CircleFull}
            alt="Bg Vector"
            className="absolute w-[40vw] opacity-60 top-[1200px] -right-[300px] z-1 ball"
          />
          <img
            src={Vector1}
            alt="Bg Vector"
            className="absolute w-[70vw] opacity-50 top-[1800px] right-[200px] z-1 ball-h"
          />
          <img
            src={Vector3}
            alt="Bg Vector"
            className="absolute w-[70vw] opacity-50 top-[2000px] right-[200px] z-1 ball-h"
          />
          {/* FOURTH SECTION BG */}
          <img
            src={CircleFull}
            alt="Bg Vector"
            className="absolute w-[40vw] opacity-60 top-[2400px] -right-[200px] z-1 ball"
          />
        </div>
      )}
      <div style={{ display: isLoaded ? 'unset' : 'none' }}>
        <div className="lg:px-28 px-6 pt-10 z-10">
          <div className="mx-md pt-20 lg:pt-30">
            <HeroSection contactFormRef={contactFormRef} />
            <Spacer />
            <AppDemoSection />
            <div ref={whySolutionSectionRef}>
              <WhySolutionSection />
            </div>
            <Spacer />
          </div>
          <div ref={plansSectionRef}>
            <PlansSection contactFormRef={contactFormRef} />
          </div>
          <Spacer />
          <div ref={contactFormRef}>
            <ContactFormSection />
          </div>
          <Spacer />
        </div>
        {/* <Button
            image={ButtonImage.MmgLogo}
            variant={ButtonVariant.Primary}
            onPress={() => window.open('https://ssm.specialquiz.it', '_blank', 'noopener,noreferrer')}
          >
            MMG
          </Button> */}

        <div className="wrapper">
          <Footer />
        </div>
      </div>
    </div>
  )
}
