import { OsTypes, osName } from 'react-device-detect'
import Logo from '../assets/logo.png'
import { Button, ButtonVariant } from '../components/Button'
import { FbEvent, scrollIntoViewWithOffset, scrollOffset, trackEvent } from '../helpers'

const DownloadButton = {
  [OsTypes.IOS]: {
    action: "Scarica l'App",
    eventType: FbEvent.AppStoreOpen,
    url: 'https://apps.apple.com/it/app/id1524977832',
  },
  [OsTypes.Android]: {
    action: "Scarica l'App",
    eventType: FbEvent.PlayStoreOpen,
    url: 'https://play.google.com/store/apps/details?id=com.specialquizapp',
  },
  [OsTypes.Windows]: { action: "Prova l'app", eventType: FbEvent.WebAppOpen, url: 'https://ssm.specialquiz.it' },
  [OsTypes.WindowsPhone]: { action: "Prova l'app", eventType: FbEvent.WebAppOpen, url: 'https://ssm.specialquiz.it' },
  [OsTypes.MAC_OS]: { action: "Prova l'app", eventType: FbEvent.WebAppOpen, url: 'https://ssm.specialquiz.it' },
}

export type LinksRef = {
  whySolutionRef: any
  plansSectionRef: any
  contactFormRef: any
  onLoaded?: () => void
}

export const Header = ({ whySolutionRef, plansSectionRef, contactFormRef, onLoaded }: LinksRef) => {
  return (
    <div
      className="fixed w-[90%] h-20 mt-2 flex flex-row mx-[5%] bg-header
         rounded-3xl backdrop-blur-[50px] border-white/20 border-[3px] overflow-hidden
         py-5 px-4 lg:px-10 items-center justify-between z-50"
      style={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), rgba(0, 0, 0, 0.2)`,
      }}
    >
      <div className="flex flex-row justify-center items-center gap-4">
        <img src={Logo} className={'w-20 h-full'} onLoad={onLoaded} />
        {/* <div className="hidden sm:flex text-white">SSM e MMG</div> */}
      </div>
      <div className="invisible lg:flex flex-row items-center overflow-hidden lg:visible gap-8">
        <Button
          variant={ButtonVariant.Transparent}
          onPress={() => {
            scrollIntoViewWithOffset(whySolutionRef, scrollOffset)
          }}
        >
          Perché Special Quiz
        </Button>

        <Button
          variant={ButtonVariant.Transparent}
          onPress={() => {
            scrollIntoViewWithOffset(plansSectionRef, scrollOffset)
          }}
        >
          <p>Soluzioni</p>
        </Button>
        <Button
          variant={ButtonVariant.Transparent}
          onPress={() => {
            scrollIntoViewWithOffset(contactFormRef, scrollOffset)
          }}
        >
          <p>Contatti</p>
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          onPress={() => {
            trackEvent(DownloadButton[osName]?.eventType)
            window.open(DownloadButton[osName]?.url, '_blank', 'noopener,noreferrer')
          }}
        >
          {DownloadButton[osName]?.action}
        </Button>
      </div>
      <div className="lg:hidden">
        <Button
          variant={ButtonVariant.Primary}
          onPress={() => window.open('https://apps.apple.com/it/app/id1524977832', '_blank', 'noopener,noreferrer')}
        >
          Scarica
        </Button>
      </div>
    </div>
  )
}
